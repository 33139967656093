import { useQuery, gql } from "@apollo/client";
import { FC } from "react";
import { datastore } from "../datastore";
import Error from "../pages/error/error";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";

const GET_CUSTOMER = gql`
  query getCustomer($extShopId: String!, $extName: String!, $id: String!) {
    getCustomer(extShopId: $extShopId, extName: $extName, id: $id) {
      id
      name
      cvr
      address
      zipCode
      city
      token
      productCategories {
        id
        name
        condition
        showOnOrderForm
        products {
          id
          name
          description
          condition
          price {
            id
            price
            supplementPrice  
            supplementOverSqMeters
            supplementSqMeterInterval
          }
          imageUrl
          subProducts {
            id
            name
            condition
            description
            price {
              id
              price
              supplementPrice  
              supplementOverSqMeters
              supplementSqMeterInterval  
            }
            imageUrl
          }
        }
      }
      photographyCompany {
        id
        name
        address
        city
        zipCode
        cvr
        email
        logoUrl
        phoneNo
        primaryColor
        secondaryColor
        textColor
        buyingTermsUrl
        showAllowOrderConfirmedNotificationButton
        showAllowOrderMessageNotificationButton
        showAllowOrderDeliveredNotificationButton
      }
    }
  }
`;

export const Customer: FC<{ children: React.ReactNode }> = ({ children }) => {
  //console.log("Getting id_token:", localStorage.getItem("id_token"));
  //console.log("Getting extShopId:", datastore.data.shopNo);
  //console.log("Getting extName:", datastore.data.providerCustomerName);
  const query = new URLSearchParams(window.location.search);
  let urlToken = query.get("t");

  let extShopId = '';
  let extName = '';
  let customerId = '';
  if (urlToken || localStorage.getItem("id_token")) {
    //debugger
    if (!urlToken) {
      urlToken = localStorage.getItem("id_token");
    }

    if (urlToken && urlToken !== "null") {
      console.log('Setting data from urlToken:', urlToken);
      localStorage.clear();
      localStorage.setItem("id_token", urlToken);
      var decodedUrlToken = jwtDecode(urlToken) as any;

      localStorage.setItem("providerCustomerName", decodedUrlToken.providerCustomerName);
      localStorage.setItem("shopNo", decodedUrlToken.shopNo);
      localStorage.setItem("customerId", decodedUrlToken.id);
      datastore.data.shopNo = decodedUrlToken.shopNo;
      datastore.data.providerCustomerName = decodedUrlToken.providerCustomerName;
      datastore.data.customerId = decodedUrlToken.id;

      extShopId = decodedUrlToken.shopNo;
      extName = decodedUrlToken.providerCustomerName;
      customerId = decodedUrlToken.id;

      //console.log('decodedUrlToken:', decodedUrlToken);
      //console.log('Setting data from urlToken - datastore:', datastore);
    }
  }

  if (!datastore.data.shopNo && !datastore.data.providerCustomerName) {
    datastore.data.shopNo = '';
    datastore.data.providerCustomerName = '';
  }


  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: {
      extShopId: extShopId !== '' ? extShopId : datastore.data.shopNo,
      extName: extName !== '' ? extName : datastore.data.providerCustomerName,
      id: customerId !== '' ? customerId : datastore.data.customerId,
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    },
  });


  if (loading) return <p></p>; //loading
  if (error) {
    console.log('error getting data from server:', error);
    if (datastore.data.shopNo) {
      if (error.message === "Customer not found") {
        return <Error errorCode={250} />;
      } else {
        localStorage.clear();
        datastore.data.shopNo = ""
        datastore.data.providerCustomerName = ""
        datastore.data.customerId = ""
        // return <Error errorCode={405} />; //TODO: make error component
      }
    }
  }
  if (!data) {

    let root = document.documentElement;
    root.style.setProperty("--text-color", "#000");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF");
    root.style.setProperty("--logoUrl", "url(" + datastore.data.photographyCompany.logoUrl + ")");
    return <>{children}</>;
  }


  if (data.getCustomer) {

    localStorage.setItem("id_token", data.getCustomer.token);
    datastore.data.customer.name = data.getCustomer.name;
    datastore.data.customer.cvr = data.getCustomer.cvr;
    datastore.data.customer.address = data.getCustomer.address;
    datastore.data.customer.zipCode = data.getCustomer.zipCode;
    datastore.data.customer.city = data.getCustomer.city;

    datastore.data.orderData.customerId = data.getCustomer.id;
    datastore.data.orderData.billingName = data.getCustomer.name;
    datastore.data.orderData.billingCvr = data.getCustomer.cvr;
    datastore.data.orderData.billingAddress = data.getCustomer.address;
    datastore.data.orderData.billingZipCode = data.getCustomer.zipCode;
    datastore.data.orderData.billingCity = data.getCustomer.city;

    datastore.data.productCategories = JSON.parse(JSON.stringify(data.getCustomer.productCategories));
    datastore.data.photographyCompany = JSON.parse(JSON.stringify(data.getCustomer.photographyCompany));

    if (datastore.data.photographyCompany.primaryColor === "") {
      datastore.data.photographyCompany.primaryColor = "#000000";
    }

    if (datastore.data.photographyCompany.secondaryColor === "") {
      datastore.data.photographyCompany.secondaryColor = "#000000";
    }

    const theme = createTheme({
      palette: {
        primary: {
          main: "#646464", //datastore.data.photographyCompany.primaryColor,
        },
        secondary: {
          main: datastore.data.photographyCompany.primaryColor,
        },
      },
    });

    let root = document.documentElement;
    root.style.setProperty("--text-color", datastore.data.photographyCompany.textColor);
    root.style.setProperty("--primary-color", datastore.data.photographyCompany.primaryColor);
    root.style.setProperty("--secondary-color", datastore.data.photographyCompany.secondaryColor);
    root.style.setProperty("--logoUrl", "url(" + datastore.data.photographyCompany.logoUrl + ")");

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  } else {

    const theme = createTheme({
      palette: {
        primary: {
          main: "#000000",
        },
        secondary: {
          main: "#000000",
        },
      },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  }
};
