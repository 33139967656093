import React from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderList.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import moment from "moment";

const GET_ORDERS = gql`
  query getOrdersByCustomer($customerId: ID!) {
    getOrdersByCustomer(customerId: $customerId) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      createdAt
      photeshotEvent {
        id
        start
      }
    }
  }
`;

const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ORDERS, {
    variables: {
      customerId: datastore.data.orderData.customerId ? datastore.data.orderData.customerId : localStorage.getItem("customerId")
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  const columns: GridColDef[] = [
    { field: 'caseNo', headerName: 'Sags nr.', width: 140, filterable: false },
    { field: 'estateAddress', headerName: 'Adresse', minWidth: 360, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 300, align: 'left' },
    { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
  ];

  const translateStatus = (order: any) => {
    switch (order.status) {
      case 'RECEIVED':
        return 'Modtaget'
      case 'SELLER_NOT_RESPONDING':
        return 'Kontaktperson træffes ikke'
      case 'AWAITING_RESPONSE_FROM_CONTACT_PERSON':
        return 'Afventer svar fra kontaktperson'
      case 'PUT_ON_HOLD':
        return 'Ordren er sat i bero'
      case 'FOTOSHOT_PLANED':
        return 'Fotografering planlagt' + (order.photeshotEvent ? ' - ' + moment(order.photeshotEvent.start).format("DD-MM-YYYY") : '')
      case 'BEING_PROCESSED':
        return 'Ordren efterbehandles'
      case 'PROCESSING_COMPLETED':
        return 'Efterbehandling er afsluttet'
      case 'DELIVERED':
        return 'Ordren er leveret'
      case 'INVOICED':
        return 'Ordren er faktureret'
      case 'CANCELED':
        return 'Ordren er annulleret'
      default:
        break;
    }
  }

  const rows = data.getOrdersByCustomer.map((order: any) => {
    return {
      id: order.id,
      caseNo: order.caseNo,
      estateAddress: order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity,
      status: translateStatus(order),
      createdAt: moment(order.createdAt).format("DD-MM-YYYY")
    }
  });

  const handleRowClicked = (row: any) => {
    
    navigate('/order/' + row.id);
    //window.location.href = '/order/' + row.id;
  }

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Bestillinger</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <DataGrid
                  style={{ borderWidth: '0px' }}
                  onRowClick={handleRowClicked}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [''],
                      },
                    },
                  }}
                  autoHeight={true}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderList;
